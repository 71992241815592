<template>
  <Page>
    <TermsAndPolicy :data="policy" />
  </Page>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Page from '@/components/Page.vue';
import TermsAndPolicy from '@/components/common/TermsAndPolicy.vue';

const { locale, getLocaleMessage } = useI18n();
const { links } = getLocaleMessage(locale.value).footer;
const policy = computed(() => links[1]);

</script>
